import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const sessionStore = new Vuex.Store({
    state: {
        isAuth: false,
        isSuperAdmin: false
    },
    mutations: {
        setAuth (state, status) {
            state.isAuth = status;
        },
        setSuperAdmin (state, status) {
            state.isSuperAdmin = status;
        }
    },
    getters: {
        isAuth: state => {
            return state.isAuth;
        },
        isSuperAdmin: state => {
            return state.isSuperAdmin;
        }
    }
});

export default sessionStore;