import SessionStore from '../store/sessionStore'

export default class Session {
    /**
     * @param {Object} user
     * @param {String} sessionToken
     */
    create(user, sessionToken) {
        localStorage.setItem('authUser', JSON.stringify(user));
        localStorage.setItem('session', sessionToken);
        SessionStore.commit('setAuth', true);
        SessionStore.commit('setSuperAdmin', this.isSuperAdmin);
    }

    /**
     *
     */
    clear() {
        localStorage.removeItem('authUser');
        localStorage.removeItem('session');
        SessionStore.commit('setAuth', false);
        SessionStore.commit('setSuperAdmin', false);
    }
    /**
     * @returns {boolean}
     */
    hasSession() {
        return localStorage.getItem('session') !== null && typeof(this.user) === 'object';
    }

    /**
     * @returns {String|null}
     */
    get token() {
        return localStorage.getItem('session');
    }

    /**
     * @returns {null|Object}
     */
    get user() {
        const userJson = localStorage.getItem('authUser');
        if(userJson === null) {
            return null;
        }
        const user = JSON.parse(userJson);
        if(typeof user !== 'object') {
            return null;
        }
        return user;
    }

    get isSuperAdmin() {
        const userJson = localStorage.getItem('authUser');
        if(userJson === null) {
            return false;
        }
        const user = JSON.parse(userJson);
        if(typeof user !== 'object') {
            return false;
        }

        return user.hasOwnProperty('roles') && user.roles.indexOf('sg_translation-superadmin') !== -1;


    }
}