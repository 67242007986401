<template>
    <div id="app" class="m-auto p-3">
        <b-button-toolbar id="nav" class="float-right">
            <b-button variant="info" v-show="authenticated" @click="logout()" title="Logout">Logout</b-button>
        </b-button-toolbar>
        <component :is="currentComponent" @authenticated="setAuthenticated"></component>
    </div>
</template>

<script>
    import Session from "./helpers/session";

    export default {
        name: 'App',
        data() {
            return {
                currentComponent: () => import('./components/login'),
                authenticated: false
            }
        },
        methods: {
            setAuthenticated(status) {
                const session = new Session();
                this.authenticated = status;
                this.$store.commit('setAuth', status);
                this.$store.commit('setSuperAdmin', session.isSuperAdmin);
            },
            logout() {
                const session = new Session();
                session.clear();

                this.authenticated = false;

                localStorage.removeItem('authUser');
                this.$store.commit('setAuth', false);
                this.$store.commit('setSuperAdmin', false);
            }
        },
        watch: {
            authenticated: function (status) {
                if (status === true) {
                    this.currentComponent = () => import('./components/translationadmin');
                } else {
                    this.currentComponent = () => import('./components/login');
                }
            }
        }
    }
</script>

<style>
    html {
        font-size: .9rem;
    }

    #nav {
        height: 0;
    }

    .interactable {
        cursor: pointer;
    }
</style>
