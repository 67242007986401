import Vue from 'vue'
import {BModal, BSpinner, BFormGroup, BButton, BButtonToolbar, TooltipPlugin, ModalPlugin} from 'bootstrap-vue'

import SessionStore from './store/sessionStore'
import App from './App.vue'
import Router from 'vue-router'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'

Vue.config.productionTip = false;
Vue.use(Router);
Vue.use(TooltipPlugin);
Vue.use(ModalPlugin);
Vue.component('b-button', BButton);
Vue.component('b-modal', BModal);
Vue.component('b-form-group', BFormGroup);
Vue.component('b-spinner', BSpinner);
Vue.component('b-button-toolbar', BButtonToolbar);

const router = new Router({
    routes: []
});

new Vue({
    el: "#app",
    router: router,
    store: SessionStore,
    render: h => h(App),
});


